import { useEffect, useState } from "react";
import { fetchStoryblok } from "@tvg/sh-lib-storyblok/src/fetchStoryblok";
import { STORYBLOK_CONTENT_PATH } from "@tvg/sh-lib-storyblok/src/constraints/config";
import tvgConf from "@tvg/conf";
import { StoryblokHomeContent } from "../types";

const HOMEPAGE_SLUG = "homepage";

const useHomeData = () => {
  const [isLoading, setLoading] = useState(false);
  const [homeData, setHomeData] = useState<StoryblokHomeContent>();

  const fetchHomeContent = async () => {
    setLoading(true);
    try {
      const data = await fetchStoryblok<StoryblokHomeContent>(
        STORYBLOK_CONTENT_PATH.EDUCATIONAL_HUB,
        HOMEPAGE_SLUG,
        tvgConf().config().storyblokVersion
      );
      setHomeData(data.content);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchHomeContent();
  }, []);

  return { homeData, isLoading };
};

export default useHomeData;
