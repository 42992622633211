import React, { FC, useEffect } from "react";
import { BinaryFn } from "@tvg/ts-types/Functional";
import HeaderComponent from "@urp/homepage/src/components/HeaderComponent";
import { ContentArticleCard, EducationHubSectionType } from "../../types";
import ArticleCard from "../ArticleCard";
import { Container, Wrapper } from "./styled-components";

interface EducationalHub {
  educationalHub: EducationHubSectionType;
  moduleRef?: React.MutableRefObject<HTMLSpanElement>;
  onSelectItem: BinaryFn<ContentArticleCard, number, void>;
  title?: string;
}

const FeaturedList: FC<EducationalHub> = ({
  educationalHub,
  moduleRef,
  onSelectItem,
  title
}) => {
  useEffect(() => {
    const moduleOnHome = moduleRef?.current;
    if (moduleOnHome) {
      moduleOnHome.hidden =
        !educationalHub?.content || educationalHub.content?.length === 0;
    }
  }, [JSON.stringify(educationalHub.content)]);

  return (
    <Wrapper>
      {title && <HeaderComponent title={title} />}
      <Container>
        {(educationalHub.content as ContentArticleCard[]).map(
          (article: ContentArticleCard, index) => (
            <span
              key={`${article.hash ?? ""}-${index.toString()}`}
              onClick={() => onSelectItem(article, index)}
            >
              <ArticleCard article={article} />
            </span>
          )
        )}
      </Container>
    </Wrapper>
  );
};

export default FeaturedList;
