import React from "react";
import { Header, useColorTokens, Paragraph } from "@tvg/design-system/web";
import { TitleWrapper, HeaderLink } from "./styled-components";

interface SeeAllLinkProps {
  url: string;
  label?: string;
}

interface HeaderComponentProps {
  qaLabel?: string;
  title?: string;
  subtitle?: string;
  seeAllLink?: SeeAllLinkProps;
}

const HeaderComponent = ({
  qaLabel = "header-component",
  title,
  subtitle,
  seeAllLink
}: HeaderComponentProps) => {
  const { content } = useColorTokens();
  const hasHeader = !!(title || subtitle);

  return (
    <>
      {hasHeader && (
        <>
          <TitleWrapper>
            {title && (
              <Header
                tag="h1"
                qaLabel={`${qaLabel}-title`}
                color={content.strong}
                fontFamily="bold"
                fontSize="var(--fd-space-space-4)"
              >
                {title}
              </Header>
            )}
            {subtitle && (
              <Header
                tag="h2"
                qaLabel={`${qaLabel}-subtitle`}
                color={content.subtle}
                fontSize="var(--fd-space-space-3)"
              >
                {subtitle}
              </Header>
            )}
          </TitleWrapper>
          {seeAllLink && (
            <HeaderLink
              to={seeAllLink.url}
              data-qa-label={`${qaLabel}-seeAllLink`}
            >
              <Paragraph qaLabel="seeAllLabel" fontFamily="bold">
                {seeAllLink.label || "See all"}
              </Paragraph>
            </HeaderLink>
          )}
        </>
      )}
    </>
  );
};

export default HeaderComponent;
