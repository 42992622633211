import { IRace } from "@fdr/types/ts/Derby";
import { HomepageOrder } from "@fdr/types/ts/Home";
import { HeaderBanner } from "@urp/derby-banner/src/types";

export interface HomepageStoryblokContent {
  default_homepage_order: HomepageOrder;
  event_day: HomepageOrder;
  pre_event: HomepageOrder;
  friday_pre_event: HomepageOrder;
  thursday_pre_event: HomepageOrder;
  event_date: string;
  event_name: string;
  event_name_size: number;
  event_subtitle: string;
  event_subtitle_size: number;
  event_description: string;
  event_description_size: number;
  track_abbr: string;
}

export enum HomepageConfigType {
  DEFAULT = "default",
  EVENT_DAY = "eventDay",
  PRE_EVENT = "preEvent",
  FRIDAY_PRE_EVENT = "fridayPreEvent",
  THURSDAY_PRE_EVENT = "thursdayPreEvent"
}

export type HomepageConfigOrder = Partial<
  Record<HomepageConfigType, HomepageOrder>
>;

export type HomepageHeaderBanner = Partial<
  Record<HomepageConfigType, Array<HeaderBanner>>
>;

export interface HomepagePromo {
  label: string;
  link: string;
}

export type HomepageCTAType = "tutorials" | "derbyRace";

export interface HomepageCTA {
  label: string;
  type: HomepageCTAType;
}

export interface HomepageBannerTexts {
  label: string;
  size: number;
}

export interface HomepageConfigEventInfo {
  eventDate: Date | null;
  eventName: HomepageBannerTexts;
  eventSubtitle: HomepageBannerTexts;
  eventDescription: HomepageBannerTexts;
  raceNumber: number;
  trackAbbr: string;
  promo: HomepagePromo;
}

export type HomepageConfigCTA = Partial<
  Record<HomepageConfigType, HomepageCTA | null>
>;

export interface HomepageConfig {
  order: HomepageConfigOrder;
  currentConfig: HomepageConfigType;
  eventInfo?: HomepageConfigEventInfo;
  derbyRace?: IRace;
  ctaButtons: HomepageConfigCTA;
  derbyBanner?: HomepageHeaderBanner;
}
