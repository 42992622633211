import StoryblokClient, { Richtext } from "storyblok-js-client";
import { ContentFaqItem } from "./types";

export function isDescriptionRichtext(
  object: ContentFaqItem["description"]
): object is Richtext {
  if ((object as Richtext).content) {
    return true;
  }
  return false;
}

export const formatFaqList = (
  data: ContentFaqItem[],
  storyblokClient?: StoryblokClient
) =>
  data.map((item, index) => ({
    id: index,
    title: item.title,
    description: isDescriptionRichtext(item.description)
      ? storyblokClient?.richTextResolver.render(item.description) || ""
      : item.description
  }));
