import React, { useMemo } from "react";

import RichText from "@fdr/atomic-ui/_atoms/RichText";
import Tag from "../../_atoms/Tag";
import {
  BetTypeContainer,
  BetTitle,
  BetTypeInfoContainer,
  InfoContainer,
  InfoWrapper,
  Label,
  Payout,
  PayoutContainer
} from "./styled-components";

const BetTypeInfo = (props) => {
  const { betType } = props;

  const renderPayoutGraph = (payout) =>
    Array(5)
      .fill(null)
      .map((param, index) => (
        <Payout
          key={`payout-${index + 1}-${payout}`}
          isYellow={index + 1 <= payout}
        />
      ));

  return useMemo(() => {
    const genQALabelTitle = betType.title.toLowerCase().replace(/ /g, "-");
    return (
      <BetTypeContainer data-qa-label={`bet-type-${genQALabelTitle}-container`}>
        <BetTypeInfoContainer
          data-qa-label={`bet-type-info-${genQALabelTitle}-container`}
        >
          <BetTitle data-qa-label={`bet-type-${genQALabelTitle}-title`}>
            {betType.title}
          </BetTitle>
          <InfoContainer>
            <InfoWrapper>
              <Tag
                qaLabel="bet-type-tag"
                name={betType.difficultyLabel}
                tagType={`${betType.difficulty
                  .replace(" ", "")
                  .toLowerCase()}Tag`}
              />
              <Label data-qa-label="label-difficulty-level">difficulty</Label>
            </InfoWrapper>
            <InfoWrapper>
              <PayoutContainer data-qa-label="payout-container">
                {renderPayoutGraph(betType.payout)}
              </PayoutContainer>
              <Label data-qa-label="label-potential-payout">
                potential payout
              </Label>
            </InfoWrapper>
          </InfoContainer>
        </BetTypeInfoContainer>
        <RichText text={betType.description} />
      </BetTypeContainer>
    );
  }, [betType]);
};

BetTypeInfo.defaultProps = {
  betType: {
    title: "",
    difficulty: "",
    payout: 0,
    description: "",
    types: []
  }
};

export default BetTypeInfo;
