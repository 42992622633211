import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CarouselSection from "@fdr/atomic-ui/_organisms/CarouselSection/";
import { ContentArticleCard } from "@fdr/tutorials/src/types";
import useHomeData from "@fdr/tutorials/src/hooks/useHomeData";
import InternalModal from "@fdr/tutorials/src/modal";
import FeaturedRacing101 from "@urp/racing-101";
import mediator from "@tvg/mediator";
import { get } from "lodash";
import { Props } from "./types";
import { getEnableHorseRacing101 } from "../../redux/selectors";

const FeaturedSection = ({ componentSectionTitle, moduleRef }: Props) => {
  const [isOpen, setOpen] = useState(false);
  const [contentSlug, setContentSlug] = useState<string>("");
  const { homeData } = useHomeData();
  const navigate = useNavigate();
  const enableHorseRacing101 = useSelector(getEnableHorseRacing101);

  const clearHash = () => navigate(window.location.pathname);

  const handleCardFooterClick = (
    slug: string,
    title: string,
    hash?: string,
    index: number = 0
  ) => {
    setOpen(true);
    setContentSlug(slug.replace("educational-hub/", ""));
    const url = hash ? `#${hash}` : undefined;
    mediator.base.dispatch({
      type: "HOMEPAGE_TUTORIALS_LEARN_MORE",
      payload: {
        article: title,
        linkUrl: url,
        position: index + 1,
        subheading: componentSectionTitle
      }
    });

    if (url) {
      navigate(url);
    }
  };

  const featuredSections =
    homeData?.sections.filter(
      (section) => section.type === "articles" && section.title === "Featured"
    ) || [];

  return (
    <>
      {enableHorseRacing101 && !!featuredSections.length && (
        <FeaturedRacing101
          educationalHub={featuredSections[0]}
          moduleRef={moduleRef}
          onSelectItem={(item: ContentArticleCard, index: number) =>
            handleCardFooterClick(
              item?.link.cached_url,
              item?.title,
              item?.hash,
              index
            )
          }
        />
      )}
      {!enableHorseRacing101 &&
        featuredSections.map((section, index) => (
          <CarouselSection
            key={`${get(section, "_uid", "")}-${index.toString()}`}
            itemInformation={section.content as ContentArticleCard[]}
            onClick={(item) => {
              handleCardFooterClick(
                item?.link.cached_url,
                item?.title,
                item?.hash,
                index
              );
            }}
          />
        ))}

      <InternalModal
        isOpen={isOpen}
        contentSlug={contentSlug}
        onClose={() => {
          clearHash();
          setOpen(false);
        }}
        clearHash={clearHash}
      />
    </>
  );
};

export default FeaturedSection;
