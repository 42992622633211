import React, { useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Button } from "@tvg/design-system/web";
import { StoryblokLink } from "../../types";
import { HORSE_RACING_101_URL } from "../../constants";

interface FooterProps {
  footerTitle: string;
  footerLink: StoryblokLink;
}

const Footer = ({ footerTitle, footerLink }: FooterProps) => {
  const { pathname, hash } = useLocation();
  const navigate = useNavigate();

  const onClickFooter = useCallback(() => {
    const cleanUrl = footerLink.cached_url.replace(
      "educational-hub/modals/",
      ""
    );

    navigate(`${HORSE_RACING_101_URL}/${cleanUrl}`, {
      state: { from: pathname, hash }
    });

    if (typeof window !== "undefined") {
      window.scrollTo(0, 0);
    }
  }, [footerLink]);

  return (
    <Button
      variant="primary"
      onClick={onClickFooter}
      qaLabel="educationalHub-footer"
      isStretched
    >
      {footerTitle}
    </Button>
  );
};

export default Footer;
