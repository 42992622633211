import React, { FC } from "react";
import { Icon, Paragraph, useTheme } from "@tvg/design-system";
import { ContentArticleCard } from "../../types";
import {
  CardWrapper,
  LearnBox,
  Text,
  TextWrapper,
  Title
} from "./styled-components";

interface ArticleCardProps {
  article: ContentArticleCard;
}

const ArticleCard: FC<ArticleCardProps> = ({ article }) => {
  const theme = useTheme();

  return (
    <CardWrapper>
      <TextWrapper>
        <Title>
          <Paragraph
            qaLabel={`article-title-${article.hash}`}
            fontFamily="bold"
            fontStyle="normal"
            color={theme.colorTokens.content.strong}
          >
            {article.title}
          </Paragraph>
        </Title>
        <Text>
          <Paragraph
            qaLabel={`article-desc-${article.hash}`}
            fontSize="s"
            mt={theme.space["space-1"]}
            color={theme.colorTokens.content.default}
          >
            {article.text}
          </Paragraph>
        </Text>
      </TextWrapper>
      <LearnBox>
        <Paragraph
          qaLabel={`article-learn-${article.hash}`}
          fontFamily="bold"
          mt={theme.space["space-1"]}
          color={theme.colorTokens.component.button.buttonLink.content.base}
        >
          Learn more
        </Paragraph>
        <Icon
          name="chevronRight"
          size="s"
          lineColor={theme.colorTokens.component.button.buttonLink.content.base}
        />
      </LearnBox>
    </CardWrapper>
  );
};

export default ArticleCard;
