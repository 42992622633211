import React from "react";
import { UnaryFn } from "@tvg/ts-types/Functional";
import Icon from "@fdr/static-ui/Icons";
import buildColor from "@fdr/static-ui/ColorPalette";
import { arrowRight, bets, active, iconList } from "@fdr/static-ui/Icons/icons";
import {
  ListContainer,
  ItemListContainer,
  InformationWrapper,
  TagIconsWrapper,
  TagIcon,
  ItemTitle,
  CardFooter,
  ItemDescription,
  PanelContainer,
  ItemWrapper
} from "./styled-components";

export interface ItemInformation {
  title: string;
  text: string;
  link: {
    cached_url: string;
  };
  hash?: string;
  icon?: string;
  legacy_tutorial_id?: number;
}

interface Props {
  itemInformation: ItemInformation[];
  isHeightChanged?: boolean;
  onClick: UnaryFn<ItemInformation, void>;
}

const CarouselSection = ({
  itemInformation = [],
  isHeightChanged = false,
  onClick
}: Props) => {
  const renderIcon = (icon: string) => {
    switch (icon) {
      case "bets":
        return bets;
      case "active":
        return active;
      case "iconList":
        return iconList;
      default:
        return active;
    }
  };

  const renderItemInformation = (item: ItemInformation, index: number) => (
    <>
      <InformationWrapper
        data-qa-label="item-information"
        isHeightChanged={isHeightChanged && !item.icon}
      >
        {item.icon && (
          <TagIconsWrapper>
            <TagIcon size={17}>
              <Icon
                icon={renderIcon(item.icon as string)}
                size={17}
                color={buildColor("grey", "D4")}
              />
            </TagIcon>
          </TagIconsWrapper>
        )}
        <ItemTitle data-qa-label={`item-title-${index}`}>
          {item.title}
        </ItemTitle>
        <ItemDescription data-qa-label={`item-description-${index}`}>
          {item.text}
        </ItemDescription>
      </InformationWrapper>

      <CardFooter data-qa-label={`footer-button-${index}`}>
        Learn more
        <Icon icon={arrowRight} color={buildColor("blue", "D1")} size={17} />
      </CardFooter>
    </>
  );

  const renderItemsList = () =>
    itemInformation.map((item: ItemInformation, index: number) => (
      <ItemListContainer
        onClick={() => onClick(item)}
        key={item.title}
        data-qa-label={`item-${index}`}
      >
        <PanelContainer>{renderItemInformation(item, index)}</PanelContainer>
      </ItemListContainer>
    ));

  return (
    <ItemWrapper data-qa-label="items-wrapper">
      {itemInformation.length > 0 && (
        <ListContainer data-qa-label="items-list">
          {renderItemsList()}
        </ListContainer>
      )}
    </ItemWrapper>
  );
};

export default CarouselSection;
