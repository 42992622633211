import React, { useState } from "react";
import { xor } from "lodash";
import { Collapse } from "react-collapse";
import buildColor from "@fdr/static-ui/ColorPalette";
import { arrowUp, arrowDown } from "@fdr/static-ui/Icons/icons";
import Icon from "@fdr/static-ui/Icons";
import RichText from "@fdr/atomic-ui/_atoms/RichText";
import Title from "@fdr/atomic-ui/_atoms/Title";
import {
  ListWrapper,
  ListItem,
  ListItemContent,
  ListItemWrapper,
  ListContainer,
  TitleContainer,
  IconWrapper,
  DescriptionWrapper
} from "./styled-components";

export interface FAQItem {
  id: number;
  title: string;
  description: string;
}

interface Props {
  faqList: FAQItem[];
}

const FrequentlyAskedQuestions = (props: Props) => {
  const [expanded, setExpanded] = useState<number[]>([]);
  const { faqList = [] } = props;

  const handleClick = (id: number) => {
    setExpanded(xor(expanded, [id]));
  };

  const renderList = (list: FAQItem[]) =>
    list.map((faqItem: FAQItem) => (
      <ListItem key={faqItem.id} data-qa-label={`listItem-${faqItem.id}`}>
        <ListItemWrapper>{renderItem(faqItem)}</ListItemWrapper>
      </ListItem>
    ));

  const renderItem = (faqItem: FAQItem) => (
    <ListItemContent data-qa-label={`faqItem-${faqItem.id}-content`}>
      <TitleContainer
        data-qa-label={`faqItem-${faqItem.id}-container`}
        onClick={() => handleClick(faqItem.id)}
      >
        <Title
          uppercase={false}
          fontSize={16}
          color={buildColor("grey", "D4")}
          qaLabel={`faqItem-${faqItem.id}-title`}
        >
          {faqItem.title}
        </Title>
        <IconWrapper>
          <Icon
            icon={expanded.includes(faqItem.id) ? arrowUp : arrowDown}
            color={buildColor("grey", "D4")}
            size={24}
            qaLabel={`faqItem-${faqItem.id}-icon`}
          />
        </IconWrapper>
      </TitleContainer>
      <Collapse
        isOpened={expanded.includes(faqItem.id)}
        springConfig={{ stiffness: 200, damping: 25 }}
        data-qa-label={`faqItem-${faqItem.id}-collapse`}
      >
        <DescriptionWrapper>
          <RichText
            text={faqItem.description}
            qaLabel={`faqItem-${faqItem.id}-content`}
          />
        </DescriptionWrapper>
      </Collapse>
    </ListItemContent>
  );

  return (
    <ListContainer data-qa-label="faqList_container">
      {faqList?.length > 0 && (
        <ListWrapper data-qa-label="faq-list">
          {renderList(faqList)}
        </ListWrapper>
      )}
    </ListContainer>
  );
};

export default FrequentlyAskedQuestions;
