import React, { ReactNode } from "react";
import { NullaryFn } from "@tvg/ts-types/Functional";
import Icon from "@fdr/static-ui/Icons";
import { arrowRight } from "@fdr/static-ui/Icons/icons";
import EducationalHubMask from "@fdr/static-ui/Masks/EducationalHubMask";
import buildColor from "@fdr/static-ui/ColorPalette";
import { Container, FooterWrapper } from "./styled-components";

interface Props {
  children: ReactNode;
  qaLabel?: string;
  isLoading?: boolean;
  hasFooter?: boolean;
  footerTitle?: string;
  onFooterClick?: NullaryFn<void>;
}

const EducationalHubTemplate = ({
  children,
  qaLabel = "educationalHub",
  isLoading,
  hasFooter,
  footerTitle,
  onFooterClick
}: Props) => (
  <Container data-qa-label={qaLabel}>
    {isLoading ? <EducationalHubMask /> : children}
    {hasFooter && footerTitle && !isLoading && (
      <FooterWrapper
        role="button"
        data-qa-label={`${qaLabel}-footer`}
        onClick={onFooterClick}
      >
        {footerTitle}
        <Icon icon={arrowRight} color={buildColor("blue")} size={17} />
      </FooterWrapper>
    )}
  </Container>
);

export default EducationalHubTemplate;
