import React, { useCallback } from "react";
import { Carousel, Button } from "@tvg/design-system/web";
import { useLocation, useNavigate } from "react-router-dom";
import { breakpoints, useMediaQuery } from "@tvg/design-system";
import { Container } from "./styled-components";
import Section from "../Section";
import ArticleItem from "../ArticleItem";
import { ContentArticleCard, EducationHubSectionType } from "../../types";
import { HORSE_RACING_101_URL } from "../../constants";

interface ArticlesProps {
  data: Array<EducationHubSectionType>;
  seeAllUrl?: string;
  displayNavButtons?: boolean;
}

interface ClickItemProps {
  slug: string;
  hash: string | undefined;
}

const Articles = ({
  data,
  seeAllUrl = "",
  displayNavButtons = true
}: ArticlesProps) => {
  const isDesktop = useMediaQuery(breakpoints.tablet.min.sm);
  const navigate = useNavigate();
  const location = useLocation();
  const isOnHomepage =
    location.pathname === "/" || location.pathname === "/home";
  const isOn101HoseRacingPage = location.pathname === HORSE_RACING_101_URL;

  const seeAllButton = (
    <Button
      variant="tertiary"
      onClick={() => navigate(seeAllUrl)}
      qaLabel="see-all-horse-racing-101-button"
      size="m"
    >
      See all
    </Button>
  );

  const onClickItem = useCallback(({ slug, hash }: ClickItemProps) => {
    const isWalkthrough = slug.startsWith("educational-hub/walkthroughs/");
    if (isWalkthrough) {
      const cleanSlug = slug.replace("educational-hub/", "");
      navigate({ hash: cleanSlug.replace("/", "-") });
    } else if (slug) {
      const cleanSlug = slug.replace("educational-hub/modals/", "");
      navigate({ pathname: `${HORSE_RACING_101_URL}/${cleanSlug}`, hash });
    }
  }, []);

  const renderContent = useCallback(
    (content, qaLabel) =>
      content.map((item: ContentArticleCard, index: number) => (
        <ArticleItem
          key={index.toString()}
          qaLabel={`${qaLabel}-item`}
          icon={item.icon}
          title={item.title}
          description={item.text}
          buttonText="Learn more"
          onClick={() =>
            onClickItem({ slug: item.link.cached_url, hash: item.hash })
          }
        />
      )),
    []
  );

  return (
    <Container>
      {data.map((section, index) => {
        const qaLabel = (section.title ?? section.tab_name)
          .replace(/ /g, "-")
          .toLowerCase();

        return (
          section.type === "articles" && (
            <Section
              key={index.toString()}
              qaLabel={`section-${qaLabel}`}
              id={section.tab_name.toLowerCase()}
              isOnHomepage={isOnHomepage}
            >
              <Carousel
                qaLabel={`${qaLabel}-carousel`}
                items={renderContent(section.content, qaLabel)}
                title={section.title}
                slideSize={164}
                slidesToScroll="auto"
                dragFree={!isDesktop}
                headerChildren={seeAllUrl ? seeAllButton : null}
                displayNavButtons={displayNavButtons}
                displayHeader={
                  isOn101HoseRacingPage ||
                  (isDesktop && !!(section.title || seeAllUrl))
                }
              />
            </Section>
          )
        );
      })}
    </Container>
  );
};

export default Articles;
