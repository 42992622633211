import { get } from "lodash";
import { parseJSONCapiMessage } from "@fdr/utils/parseJSONCapiMessage";
import { DerbyEventStatus, IRace } from "@fdr/types/ts/Derby";

import defaultOrder from "../constraints/defaultOrder";
import {
  HomepageConfig,
  HomepageConfigOrder,
  HomepageConfigEventInfo,
  HomepageConfigType
} from "../utils/types";
import { State } from "./types";

type UTCDate = [number, number, number];
const MONTH_INDEX = 1;

export const getHomepageConfig = (store: State): HomepageConfig =>
  get(store, "home", {
    order: {
      default: {
        header: [],
        body: [],
        unmapped: defaultOrder
      }
    },
    currentConfig: HomepageConfigType.DEFAULT,
    ctaButtons: {}
  });

export const getHomepageOrder = (store: State): HomepageConfigOrder =>
  get(store, "home.order", {
    default: {
      header: [],
      body: [],
      unmapped: defaultOrder
    }
  });

export const getHomepageEventInfo = (
  store: State
): HomepageConfigEventInfo | undefined => {
  const eventInfo = get(store, "home.eventInfo", undefined);
  return {
    ...eventInfo,
    eventDate: getEventDate(store)
  };
};

export const getHomepageEventTrackAbbr = (store: State): string =>
  get(store, "home.eventInfo.trackAbbr", "");

export const getHomepageCurrentConfig = (store: State): DerbyEventStatus =>
  get(store, "home.currentConfig", DerbyEventStatus.POST);

export const getHasRequest = (store: State): boolean =>
  get(store, "home.hasRequest", false);

export const getEnableStoryblokConfig = (store: State): boolean =>
  get(store, "capi.featureToggles.enableStoryblokConfig", false);

export const getEnableHorseRacing101 = (store: State): boolean =>
  get(store, "capi.featureToggles.horseRacing101", false);

export const getEventDate = (store: State): Date | null =>
  new Date(get(store, "home.eventInfo.eventDate", null));

export const getDerbyRace = (store: State): IRace | undefined =>
  get(store, "home.derbyRace");

export const getTodayDate = (store: State): Date => {
  const currentLocalDate = new Date();
  const raceDate: UTCDate = get(store, "mtpStatus.raceDate", "")
    .split("-")
    .map((date: string, i: number) => {
      const parsedDate = +date;
      return MONTH_INDEX === i ? parsedDate - 1 : parsedDate;
    });
  const todayDate: UTCDate =
    raceDate.length === 3
      ? raceDate
      : [
          currentLocalDate.getUTCFullYear(),
          currentLocalDate.getUTCMonth(),
          currentLocalDate.getUTCDate()
        ];

  return new Date(Date.UTC(...todayDate));
};

export const getPesEventInfo = (
  store: State
): HomepageConfigEventInfo | undefined => {
  const pesEventConfig = parseJSONCapiMessage(
    store,
    "capi.messages.pesEventConfig",
    {}
  );
  const eventDate = pesEventConfig?.event?.eventDate;
  return {
    ...pesEventConfig,
    event: {
      ...pesEventConfig.event,
      eventDate: eventDate ? new Date(eventDate) : null
    }
  };
};

export const getSeoHomeTitle = (store: State) =>
  get(store, "capi.messages.seoHomeTitle", "");

export const getSeoContentHome = (store: State) =>
  get(store, "capi.messages.seoContentHome", "");

export const getUseTopRacesDS = (store: State) =>
  get(store, "capi.featureToggles.useTopRacesDS", false);

export const getUrpTipsAndPicksToggle = (store: State) =>
  get(store, "capi.featureToggles.enableURPTipsAndPicks", false);

export const getEnableNewUpcomingRaces = (store: State) =>
  get(store, "capi.featureToggles.enableNewUpcomingRaces", false);

export const getEnableHomepagePillsNav = (store: State) =>
  get(store, "capi.featureToggles.enableHomepagePillsNav", false);

export const getHasCrossNavigation = (store: State) =>
  get(store, "app.hasCrossNavigation", false);

export const getEnableURPEducationHub = (store: State): boolean =>
  get(store, "capi.featureToggles.enableURPEducationHub", false);

export const getEnableURPEducationHubXSell = (store: State): boolean =>
  get(store, "capi.featureToggles.enableURPEducationHubXSell", false);

export const getShowNewBanner = (store: State) =>
  get(store, "capi.featureToggles.showNewBannersStoryblock", false);
