import { useEffect, useState } from "react";
import { fetchStoryblok } from "@tvg/sh-lib-storyblok/src/fetchStoryblok";
import { STORYBLOK_CONTENT_PATH } from "@tvg/sh-lib-storyblok/src/constraints/config";
import { Dispatch } from "redux";
import tvgConf from "@tvg/conf";
import { StoryblokModalContent } from "../types";
import { setStoredContentSlugs } from "../redux/actions";

const useContentData = (
  selectedSlug: string,
  dispatch: Dispatch,
  storedSlugContent?: StoryblokModalContent
) => {
  const [isLoading, setLoading] = useState(false);
  const [contentData, setContentData] = useState<StoryblokModalContent>();

  useEffect(() => {
    if (selectedSlug) {
      fetchContentData(selectedSlug);
    }
  }, [selectedSlug]);

  const fetchContentData = async (slug: string) => {
    setLoading(true);

    try {
      if (!storedSlugContent) {
        const data = await fetchStoryblok<StoryblokModalContent>(
          STORYBLOK_CONTENT_PATH.EDUCATIONAL_HUB,
          slug,
          tvgConf().config().storyblokVersion
        );
        dispatch(setStoredContentSlugs({ key: slug, data: data.content }));
        setContentData(data.content);
      } else {
        setContentData(storedSlugContent);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return { contentData, fetchContentData, isLoading };
};

export default useContentData;
