import { StoryblokModalContent } from "@fdr/tutorials/src/types";

export interface ISetStoredSlugAction {
  key: string;
  data: StoryblokModalContent;
}

export interface SetStoreSlugAction {
  type: "SET_STORED_CONTENT_SLUGS";
  payload: ISetStoredSlugAction;
}

export interface SetSelectedSlugAction {
  type: "SET_SELECTED_SLUG";
  payload: string;
}

export const setStoredContentSlugs = (payload: ISetStoredSlugAction) => ({
  type: "SET_STORED_CONTENT_SLUGS",
  payload
});

export const setSelectedSlug = (payload: string | undefined) => ({
  type: "SET_SELECTED_SLUG",
  payload
});
