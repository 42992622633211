import React from "react";
import DOMPurify from "dompurify";
import { RichTextStyled } from "./styled-components";

interface Props {
  qaLabel?: string;
  hasPadding?: boolean;
  text: string;
  className?: string;
}

export const RichText = ({
  qaLabel = "richText",
  hasPadding,
  text,
  className
}: Props) => (
  <RichTextStyled
    data-qa-label={qaLabel}
    hasPadding={hasPadding}
    dangerouslySetInnerHTML={{
      __html: DOMPurify.sanitize(text)
    }}
    className={className}
  />
);

export default RichText;
