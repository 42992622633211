import { ComponentName } from "@fdr/types/ts/Home";
import { ComponentConfig } from "./types";

export const defaultOrderBody: Array<ComponentConfig> = [
  {
    id: 298,
    name: ComponentName.PROMO_BANNER_TOP,
    description: "",
    subtitle: ""
  },
  {
    id: 299,
    name: ComponentName.UPCOMING_RACES,
    description: "Upcoming Races",
    subtitle: ""
  },
  {
    id: 300,
    name: ComponentName.TOP_RACES,
    description: "Today's Popular Races",
    subtitle: ""
  },
  {
    id: 302,
    name: ComponentName.TOP_TRACKS,
    description: "Top Tracks",
    subtitle: ""
  },
  {
    id: 476,
    name: ComponentName.PROMO_BANNER_BOTTOM,
    description: "",
    subtitle: ""
  }
];
export const defaultOrderHeader: Array<ComponentConfig> = [
  {
    id: 297,
    name: ComponentName.QUICK_LINKS,
    description: "",
    subtitle: ""
  }
];

export default defaultOrderBody;
