export enum ComponentName {
  QUICK_LINKS = "QUICK_LINKS",
  TOP_RACES = "TOP_RACES",
  TOP_TRACKS = "TOP_TRACKS",
  UPCOMING_RACES = "UPCOMING_RACES",
  PROMO_WIDGET = "PROMO_WIDGET",
  SEO_FOOTER = "SEO_FOOTER",
  PROMO_BANNER_TOP = "PROMO_BANNER_TOP",
  PROMO_BANNER_BOTTOM = "PROMO_BANNER_BOTTOM",
  TOP_POOLS = "TOP_POOLS",
  TALENT_PICKS = "TALENT_PICKS",
  HOME_PLAYLIST = "HOME_PLAYLIST",
  HORSE_RACING_101 = "HORSE_RACING_101",
  PREVIOUS_WINNERS = "PREVIOUS_WINNERS",
  DEFAULT = "DEFAULT"
}

export interface HomepageItem {
  name: ComponentName;
  description: string;
  without_background?: boolean;
  id?: number;
  component?: string;
  subtitle?: string;
  _uid?: string;
  _editable?: string;
  hide_on_xsell?: boolean;
  hide_on_fdr?: boolean;
}

export interface HomepageOrder {
  header: Array<HomepageItem>;
  body: Array<HomepageItem> | undefined;
  unmapped: Array<HomepageItem>;
}
