import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { Device } from "@tvg/ts-types/Device";
import { breakpoints, useMediaQuery } from "@tvg/design-system/src";
import FeaturedSection from "@fdr/home/src/components/featuredSection";
import { isXSell } from "@tvg/sh-utils/mobileUtils";
import useHomeData from "../../hooks/useHomeData";
import Articles from "../Articles";
import { ArticleSectionLoading } from "../Loadings/Components";
import WalkthroughModal from "../WalkthroughModal";
import { HORSE_RACING_101_URL } from "../../constants";
import {
  getEnableURPEducationHub,
  getEnableURPEducationHubXSell,
  getShowStoryblokHomeToggle
} from "../../selectors";
import { Racing101SectionProps } from "./types";

const Racing101Section = ({ title, device }: Racing101SectionProps) => {
  const enableURPEducationHub = useSelector(getEnableURPEducationHub);
  const enableURPEducationHubXSell = useSelector(getEnableURPEducationHubXSell);
  const isNewHomepageEnabled = useSelector(getShowStoryblokHomeToggle);

  const isMobile =
    useMediaQuery(breakpoints.tablet.max.sm) || device === Device.MOBILE;

  const { homeData, isLoading } = useHomeData();

  const featuredSections = useMemo(
    () =>
      homeData?.sections
        .filter(
          (section) =>
            section.type === "articles" && section.title === "Featured"
        )
        .map((section, index) =>
          index === 0 ? { ...section, title } : section
        ) || [],
    [homeData, title]
  );

  // To render the old featured section on mobile if the corresponding toggle is off
  // This is only needed for the new mobile homepage
  if (
    isNewHomepageEnabled &&
    ((isXSell() && !enableURPEducationHubXSell) ||
      (!isXSell() && isMobile && !enableURPEducationHub))
  ) {
    return <FeaturedSection componentSectionTitle={title} />;
  }

  // To not render the component on Desktop if the toggle is off
  if (!enableURPEducationHub) return null;

  if (isLoading) {
    return <ArticleSectionLoading />;
  }

  return (
    <>
      <Articles
        data={featuredSections}
        seeAllUrl={!isMobile ? HORSE_RACING_101_URL : undefined}
        displayNavButtons={!isMobile}
      />
      <WalkthroughModal />
    </>
  );
};

export default Racing101Section;
